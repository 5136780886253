var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            title: "Cập nhật thông tin trường",
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateTabSchool",
              attrs: {
                "label-width": "160px",
                model: _vm.updateTabSchool,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleTabAccount },
                  model: {
                    value: _vm.activeTabName,
                    callback: function ($$v) {
                      _vm.activeTabName = $$v
                    },
                    expression: "activeTabName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "inforTab" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên trường", prop: "schoolName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập tên trường" },
                            model: {
                              value: _vm.updateTabSchool.schoolName,
                              callback: function ($$v) {
                                _vm.$set(_vm.updateTabSchool, "schoolName", $$v)
                              },
                              expression: "updateTabSchool.schoolName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Chọn đại lý", prop: "idAgent" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "button-left",
                              attrs: {
                                placeholder: "-Chọn đại lý-",
                                clearable: "",
                              },
                              model: {
                                value: _vm.updateTabSchool.agent.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.updateTabSchool.agent, "id", $$v)
                                },
                                expression: "updateTabSchool.agent.id",
                              },
                            },
                            _vm._l(_vm.agentListSearch, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.agentName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "35px" },
                          attrs: { label: "Mô tả", prop: "schoolDescription" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              rows: 7,
                              type: "textarea",
                              placeholder: "Nhập mô tả",
                            },
                            model: {
                              value: _vm.updateTabSchool.schoolDescription,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateTabSchool,
                                  "schoolDescription",
                                  $$v
                                )
                              },
                              expression: "updateTabSchool.schoolDescription",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Địa chỉ", prop: "schoolAddress" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập địa chỉ" },
                            model: {
                              value: _vm.updateTabSchool.schoolAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateTabSchool,
                                  "schoolAddress",
                                  $$v
                                )
                              },
                              expression: "updateTabSchool.schoolAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-below",
                          attrs: { label: "Điện thoại", prop: "schoolPhone" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập số điện thoại" },
                            model: {
                              value: _vm.updateTabSchool.schoolPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateTabSchool,
                                  "schoolPhone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "updateTabSchool.schoolPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-below",
                          attrs: { label: "Email", prop: "schoolEmail" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập email" },
                            model: {
                              value: _vm.updateTabSchool.schoolEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateTabSchool,
                                  "schoolEmail",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "updateTabSchool.schoolEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-below",
                          staticStyle: {
                            width: "290px",
                            "margin-left": "58px",
                          },
                          attrs: { label: "Website", prop: "schoolWebsite" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập website" },
                            model: {
                              value: _vm.updateTabSchool.schoolWebsite,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateTabSchool,
                                  "schoolWebsite",
                                  $$v
                                )
                              },
                              expression: "updateTabSchool.schoolWebsite",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Ảnh bìa" } },
                                [
                                  _vm.updateTabSchool.schoolAvatar != null &&
                                  _vm.showPicutreInitial
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "150px",
                                          height: "150px",
                                          float: "left",
                                          "margin-bottom": "10px",
                                        },
                                        attrs: {
                                          src: _vm.updateTabSchool.schoolAvatar,
                                          fit: "fill",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      staticClass: "avatar-uploader",
                                      class: { hideUpload: !_vm.showUpload },
                                      attrs: {
                                        action: "",
                                        "show-file-list": true,
                                        "list-type": "picture-card",
                                        "on-change": _vm.handleChangeUpload,
                                        "on-remove": _vm.handleRemove,
                                        "auto-upload": false,
                                        limit: 1,
                                      },
                                    },
                                    [
                                      _vm.updateTabSchool.schoolAvatar != null
                                        ? _c("span", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                              },
                                              [_vm._v("Cập nhật ảnh")]
                                            ),
                                          ])
                                        : _c("span", [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                              },
                                              [_vm._v("Thêm ảnh")]
                                            ),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 17 } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Liên hệ 1",
                                            prop: "contactPhone1",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Số điện thoại 1",
                                            },
                                            model: {
                                              value:
                                                _vm.updateTabSchool
                                                  .contactPhone1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.updateTabSchool,
                                                  "contactPhone1",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "updateTabSchool.contactPhone1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: {
                                            width: "85%",
                                            "padding-left": "0px",
                                          },
                                          attrs: {
                                            label: "Tên",
                                            prop: "contactName1",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhập nội dung",
                                            },
                                            model: {
                                              value:
                                                _vm.updateTabSchool
                                                  .contactName1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.updateTabSchool,
                                                  "contactName1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "updateTabSchool.contactName1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Liên hệ 2",
                                            prop: "contactPhone2",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Số điện thoại 2",
                                            },
                                            model: {
                                              value:
                                                _vm.updateTabSchool
                                                  .contactPhone2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.updateTabSchool,
                                                  "contactPhone2",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "updateTabSchool.contactPhone2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Tên",
                                            prop: "contactName2",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhập nội dung",
                                            },
                                            model: {
                                              value:
                                                _vm.updateTabSchool
                                                  .contactName2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.updateTabSchool,
                                                  "contactName2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "updateTabSchool.contactName2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Liên hệ 3",
                                            prop: "contactPhone3",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Số điện thoại 3",
                                            },
                                            model: {
                                              value:
                                                _vm.updateTabSchool
                                                  .contactPhone3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.updateTabSchool,
                                                  "contactPhone3",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "updateTabSchool.contactPhone3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Tên",
                                            prop: "contactName3",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhập nội dung",
                                            },
                                            model: {
                                              value:
                                                _vm.updateTabSchool
                                                  .contactName3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.updateTabSchool,
                                                  "contactName3",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "updateTabSchool.contactName3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thiết lập", name: "settingTab" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Dùng thử" } },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.updateTabSchool.trialStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateTabSchool,
                                  "trialStatus",
                                  $$v
                                )
                              },
                              expression: "updateTabSchool.trialStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Dùng thử từ",
                                      prop: "demoStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          !_vm.updateTabSchool.trialStatus,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.updateTabSchool.demoStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "demoStart",
                                            $$v
                                          )
                                        },
                                        expression: "updateTabSchool.demoStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Đến ngày",
                                      prop: "demoEnd",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          !_vm.updateTabSchool.trialStatus,
                                        "value-format": "yyyy-MM-dd",
                                        format: "dd-MM-yyyy",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.updateTabSchool.demoEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "demoEnd",
                                            $$v
                                          )
                                        },
                                        expression: "updateTabSchool.demoEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày bắt đầu",
                                      prop: "dateStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          _vm.updateTabSchool.trialStatus,
                                        "value-format": "yyyy-MM-dd",
                                        format: "dd-MM-yyyy",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.updateTabSchool.dateStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "dateStart",
                                            $$v
                                          )
                                        },
                                        expression: "updateTabSchool.dateStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày hết hạn",
                                      prop: "dateEnd",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          _vm.updateTabSchool.trialStatus,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.updateTabSchool.dateEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "dateEnd",
                                            $$v
                                          )
                                        },
                                        expression: "updateTabSchool.dateEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày ký HĐ",
                                      prop: "dateContractStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.updateTabSchool.dateContractStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "dateContractStart",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "updateTabSchool.dateContractStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Kết thúc HĐ",
                                      prop: "dateContractEnd",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.updateTabSchool.dateContractEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "dateContractEnd",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "updateTabSchool.dateContractEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "limitTime",
                                      "label-width": "230px",
                                      label: "Giới hạn thời gian",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.updateTabSchool.limitTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.updateTabSchool,
                                              "limitTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "updateTabSchool.limitTime",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("Có")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticStyle: {
                                              "margin-left": "38px",
                                            },
                                            attrs: { label: false },
                                          },
                                          [_vm._v("Không")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "230px",
                                      prop: "schoolActive",
                                      label: "Trạng thái",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.updateTabSchool.schoolActive,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.updateTabSchool,
                                              "schoolActive",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "updateTabSchool.schoolActive",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("Kích hoạt")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("Hủy kích hoạt")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "230px",
                                      prop: "smsActiveMore",
                                      label: "Sử dụng SMS",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.updateTabSchool.smsActiveMore,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.updateTabSchool,
                                              "smsActiveMore",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "updateTabSchool.smsActiveMore",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("Mặc định")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("Vượt mức")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "smsBudget",
                                      "label-width": "190px",
                                      label: "Mức SMS mặc định",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.updateTabSchool.smsBudget,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.updateTabSchool,
                                            "smsBudget",
                                            $$v
                                          )
                                        },
                                        expression: "updateTabSchool.smsBudget",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tab-infor-button" },
                    [
                      _vm.showButtonTabAccount
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "medium" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeDialogByButton(
                                    "updateTabSchool"
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-circle-close" }),
                              _c("span", [_vm._v("Đóng")]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.statusSave && _vm.showButtonTabAccount
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "medium",
                                loading: _vm.loadingButton,
                                mini: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("updateTabSchool")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-circle-check" }),
                              _c("span", [_vm._v("Lưu")]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }