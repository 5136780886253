var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: "Thêm trường mới",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createTabSchool",
              attrs: {
                "label-width": "160px",
                model: _vm.createTabSchool,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeTabName,
                    callback: function ($$v) {
                      _vm.activeTabName = $$v
                    },
                    expression: "activeTabName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "tabInfo" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên trường", prop: "schoolName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập tên trường" },
                            model: {
                              value: _vm.createTabSchool.schoolName,
                              callback: function ($$v) {
                                _vm.$set(_vm.createTabSchool, "schoolName", $$v)
                              },
                              expression: "createTabSchool.schoolName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Chọn đại lý", prop: "idAgent" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "button-left",
                              attrs: {
                                placeholder: "Chọn đại lý",
                                clearable: "",
                              },
                              model: {
                                value: _vm.createTabSchool.idAgent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.createTabSchool, "idAgent", $$v)
                                },
                                expression: "createTabSchool.idAgent",
                              },
                            },
                            _vm._l(_vm.agentListSearch, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.agentName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "35px" },
                          attrs: { label: "Mô tả", prop: "schoolDescription" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              rows: 7,
                              type: "textarea",
                              placeholder: "Nhập mô tả",
                            },
                            model: {
                              value: _vm.createTabSchool.schoolDescription,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTabSchool,
                                  "schoolDescription",
                                  $$v
                                )
                              },
                              expression: "createTabSchool.schoolDescription",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Địa chỉ", prop: "schoolAddress" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập địa chỉ" },
                            model: {
                              value: _vm.createTabSchool.schoolAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTabSchool,
                                  "schoolAddress",
                                  $$v
                                )
                              },
                              expression: "createTabSchool.schoolAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-below",
                          attrs: { label: "Điện thoại", prop: "schoolPhone" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              min: "0",
                              max: "0",
                              placeholder: "Nhập số điện thoại",
                            },
                            model: {
                              value: _vm.createTabSchool.schoolPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTabSchool,
                                  "schoolPhone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "createTabSchool.schoolPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-below",
                          attrs: { label: "Email", prop: "schoolEmail" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập email" },
                            model: {
                              value: _vm.createTabSchool.schoolEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTabSchool,
                                  "schoolEmail",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "createTabSchool.schoolEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "input-below",
                          staticStyle: {
                            width: "290px",
                            "margin-left": "58px",
                          },
                          attrs: { label: "Website", prop: "schoolWebsite" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập website" },
                            model: {
                              value: _vm.createTabSchool.schoolWebsite,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTabSchool,
                                  "schoolWebsite",
                                  $$v
                                )
                              },
                              expression: "createTabSchool.schoolWebsite",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "input-below",
                                  attrs: { label: "Ảnh bìa" },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      staticClass: "avatar-uploader",
                                      class: { hideUpload: !_vm.showUpload },
                                      attrs: {
                                        "show-file-list": true,
                                        "list-type": "picture-card",
                                        "on-change": _vm.toggleUpload,
                                        "on-remove": _vm.handleRemove,
                                        "auto-upload": false,
                                        limit: "1",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "el-icon-plus avatar-uploader-icon",
                                          },
                                          [_vm._v("Thêm ảnh")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 17 } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Liên hệ 1",
                                            prop: "contactPhone1",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Số điện thoại 1",
                                            },
                                            model: {
                                              value:
                                                _vm.createTabSchool
                                                  .contactPhone1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.createTabSchool,
                                                  "contactPhone1",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "createTabSchool.contactPhone1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: {
                                            width: "85%",
                                            "padding-left": "0px",
                                          },
                                          attrs: {
                                            label: "Tên",
                                            prop: "contactName1",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhập nội dung",
                                            },
                                            model: {
                                              value:
                                                _vm.createTabSchool
                                                  .contactName1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.createTabSchool,
                                                  "contactName1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "createTabSchool.contactName1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Liên hệ 2",
                                            prop: "contactPhone2",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Số điện thoại 2",
                                            },
                                            model: {
                                              value:
                                                _vm.createTabSchool
                                                  .contactPhone2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.createTabSchool,
                                                  "contactPhone2",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "createTabSchool.contactPhone2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Tên",
                                            prop: "contactName2",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhập nội dung",
                                            },
                                            model: {
                                              value:
                                                _vm.createTabSchool
                                                  .contactName2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.createTabSchool,
                                                  "contactName2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "createTabSchool.contactName2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Liên hệ 3",
                                            prop: "contactPhone3",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Số điện thoại 3",
                                            },
                                            model: {
                                              value:
                                                _vm.createTabSchool
                                                  .contactPhone3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.createTabSchool,
                                                  "contactPhone3",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "createTabSchool.contactPhone3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "input-below",
                                          staticStyle: { width: "85%" },
                                          attrs: {
                                            label: "Tên",
                                            prop: "contactName3",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Nhập nội dung",
                                            },
                                            model: {
                                              value:
                                                _vm.createTabSchool
                                                  .contactName3,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.createTabSchool,
                                                  "contactName3",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "createTabSchool.contactName3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thiết lập" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Dùng thử", prop: "trialStatus" } },
                        [
                          _c("el-checkbox", {
                            model: {
                              value: _vm.createTabSchool.trialStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTabSchool,
                                  "trialStatus",
                                  $$v
                                )
                              },
                              expression: "createTabSchool.trialStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Dùng thử từ",
                                      prop: "demoStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          !_vm.createTabSchool.trialStatus,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.createTabSchool.demoStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "demoStart",
                                            $$v
                                          )
                                        },
                                        expression: "createTabSchool.demoStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Đến ngày",
                                      prop: "demoEnd",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          !_vm.createTabSchool.trialStatus,
                                        "value-format": "yyyy-MM-dd",
                                        format: "dd-MM-yyyy",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.createTabSchool.demoEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "demoEnd",
                                            $$v
                                          )
                                        },
                                        expression: "createTabSchool.demoEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày bắt đầu",
                                      prop: "dateStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          _vm.createTabSchool.trialStatus,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.createTabSchool.dateStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "dateStart",
                                            $$v
                                          )
                                        },
                                        expression: "createTabSchool.dateStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày hết hạn",
                                      prop: "dateEnd",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled:
                                          _vm.createTabSchool.trialStatus,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value: _vm.createTabSchool.dateEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "dateEnd",
                                            $$v
                                          )
                                        },
                                        expression: "createTabSchool.dateEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày ký HĐ",
                                      prop: "dateContractStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        format: "dd-MM-yyyy",
                                        type: "date",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.createTabSchool.dateContractStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "dateContractStart",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "createTabSchool.dateContractStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Kết thúc HĐ",
                                      prop: "dateContractEnd",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        format: "dd-MM-yyyy",
                                        type: "date",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.createTabSchool.dateContractEnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "dateContractEnd",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "createTabSchool.dateContractEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "limitTime",
                                      "label-width": "230px",
                                      label: "Giới hạn thời gian",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.createTabSchool.limitTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.createTabSchool,
                                              "limitTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "createTabSchool.limitTime",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("Có")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticStyle: {
                                              "margin-left": "38px",
                                            },
                                            attrs: { label: false },
                                          },
                                          [_vm._v("Không")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "230px",
                                      prop: "schoolActive",
                                      label: "Trạng thái",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.createTabSchool.schoolActive,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.createTabSchool,
                                              "schoolActive",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "createTabSchool.schoolActive",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("Kích hoạt")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("Hủy kích hoạt")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "smsActiveMore",
                                      "label-width": "230px",
                                      label: "Sử dụng SMS",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.createTabSchool.smsActiveMore,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.createTabSchool,
                                              "smsActiveMore",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "createTabSchool.smsActiveMore",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("Mặc định")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v("Vượt mức")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "smsBudget",
                                      "label-width": "190px",
                                      label: "Mức SMS mặc định",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.createTabSchool.smsBudget,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.createTabSchool,
                                            "smsBudget",
                                            $$v
                                          )
                                        },
                                        expression: "createTabSchool.smsBudget",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tab-infor-button" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialogByButton("createTabSchool")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-close" }),
                          _c("span", [_vm._v("Đóng")]),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            size: "medium",
                            loading: _vm.loadingButton,
                            mini: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("createTabSchool")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-check" }),
                          _c("span", [_vm._v("Lưu")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }